<template>
          <!-- navbar-started -->
        <div class="ctx-email-app-navbar">
          <div class="ctx-email-app-logo-box">
            <div class="ctx-email-app-logo-icon">
              <i class="dripicons-inbox"></i>
            </div>
            <div class="ctx-email-app-logo-title" @click="routeToHome">
              <span class="heading">ConnectXone</span>
              <div class="connected_icon_view" :class="is_connected  ?'' : 'connection_failed'">
              </div>
              <!-- <span class="title">Email Channel</span> -->
            </div>
          </div>
          <div class="ctx-channels-preview">
            <div class="active_channel_box">  
             
             <div class="icon_view_box" :class="channel.id == active_channel_id  ? 'active_channel_view' : ''"
             v-for="(channel, index) in channels" :key="index"
                @click="switchChannel(channel)"
             ><i :class="channel.icon" :style="{color:channel.icon_color + ' !important'}">
             </i>
             <div class="notification_badge_view" v-if="channel.unread_messages !== 0">
              <span>{{ channel.unread_messages }}</span>
             </div>
            <div class="ctx-channels-drop_down">
               <!-- channel list start -->
                <div class="ctx-channels-items"
                >
                   <div class="icon-box" :style="{color:channel.icon_color + ' !important'}"><i :class="channel.icon"></i></div>
                  
                  <div class="title">
                    <span class="heading">{{ channel.title }}</span>
                    <span class="email">{{ channel.email_account.username }}</span>
                  </div>
                </div>
                <!-- channel preview end -->
            </div>
             </div>
             </div>
            <!-- <span class="heading">{{ active_channel == null ? 'Channels' : active_channel.title + ' - ' + active_channel.email_account.username }}
            </span> -->
            <!-- <i class="ri ri-more-2-fill bar-icon"></i></div> -->
          
          </div>
          <div class="ctx-profile-app-box">
            <div class="ctx-profile-preview-text" @click="showProfileMenu">{{ user.name }}</div>
            <div class="ctx-profile-app-icon-box" @click="showProfileMenu">
              <i class="ri ri-user-line" v-if="user.photo == ''"></i>
              <img class="rounded-circle header-profile-user" v-else-if="user.photo !== ''" :src="user.photo"
                alt="Header Avatar" />
            </div>
                <div class="ctx-channels_profile_drop_down" v-if="is_channel_profile">
               <!-- channel list start -->
                <div class="ctx-channels-items"
                @click="showEmailAccount"
                >
                   <div class="icon-box"><i class="fas fa-mail-bulk"></i></div>
                  
                  <div class="title">
                    <span class="heading">Account Setting</span>
                  </div>
                </div>
                <!-- channel preview end -->
                  <!-- channel list start -->
                <div class="ctx-channels-items"
                @click="logout"
                >
                   <div class="icon-box"><i class="mdi mdi-logout"></i></div>
                  
                  <div class="title">
                    <span class="heading">Logout</span>
                  </div>
                </div>
                <!-- channel preview end -->
            </div>
          </div>
          <modal
                   name="email-account-modal"
                    :width="1000" height="auto" draggable=".modal-header"
                    
                  >
                   <div class="modal-item">
                   <div class="modal-header">
        <span>Account Setting Details</span>
        <i class="mdi mdi-close-thick" @click="$modal.hide('email-account-modal')"></i>
      </div>
      </div>

       <div class="modal-contact-body-view">
          <EmailAccount />
       </div>
                 
                  </modal>
        </div>

        <!-- navbar view ended -->
</template>

<script>
import { emailAccountComputed, emailAccountMethods } from "../state/helpers/eaccount";
import { communicationChannelMethods, communicationChannelComputed } from "../state/helpers/communicationchannel";
import { emailNotificationMethods } from "../state/helpers/emailnotification";
import { authUserComputed, authUserMethods } from "../state/helpers/authuser";
import { inboxMethods } from "../state/helpers/inbox";
import EmailAccount from "../components/email_account"
import { layoutMethods } from "../state/helpers";
export default {
  computed: {
    ...authUserComputed,
    ...emailAccountComputed,
    ...communicationChannelComputed,
    channels: {
      get: function () {
        return this.getAllChannels;
      },
      set: function (value) {
        return value
      }
    },
    user: {
      get: function () {
        return this.getUser;
      },
    },
    active_communication_channel(){
      return this.getActiveCommunicationChannel
    }
  },
    components: {
      EmailAccount,
    },
  data() {
    return {
      accounts: null,
      account_title: "Email Accounts",
      active_channel: null,
      is_show_menu:false,
      is_channel_profile:false,
      active_channel_id: '',
      is_connected:true,
    };
  },
    methods: {
      ...layoutMethods,
    ...authUserMethods,
    ...emailAccountMethods,
    ...communicationChannelMethods,
    ...emailNotificationMethods,
    ...inboxMethods,
    loadChannels() {
      return this.allChannel({}).then((response) => {
        if (response) {
          this.channels = response;
        }
      });
    },
    loadFolders(id){
      this.allFolders(id)
        .then(() => {
          
        })
        .catch((error) => {
          return error;
        });
    },
    loadTags(id){
     return this.allTags(id);
    },
    showEmailAccount(){
      this.$modal.show("email-account-modal");
      this.is_channel_profile = false;
    },
    showChannelMenu(){
      this.is_show_menu = !this.is_show_menu;
    },
    showProfileMenu(){
      this.is_channel_profile = !this.is_channel_profile;
    },
    loadNotification() {
      // window.cxSocketApi.subscribe((data) => {
      //   if(data){
      //     this.loadChannels();

      //     const account_id = localStorage.getItem("email_account_id");
          
      //     if (account_id !== null) {
      //       if (account_id !== undefined) {
      //         let contact_data = {
      //           search_term: "",
      //           last_contact_id: 0,
      //           account_id: +account_id,
      //           limit: 24
      //         }
      //         this.activeCommunicationChannel(account_id)
      //         this.allEmailContact(contact_data)
      //       }
      //   }
      
      //   }
      // }, (response) => {
      //   console.log(response)
      // }, (error) => {
      //   console.log(error)
      // }, { name: "new-message"})

    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },

    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },

    routeToHome(){
      this.$router.push('/').catch(() => { })
    },
    loadContact(id){
   let contact_data = {
          search_term: "",
          last_contact_id: 0,
          account_id: id,
          limit: 23
        }

        this.allEmailContact(contact_data)
    },
    switchChannel(data) {
      this.active_channel = data;
      this.active_channel_id = data.id;
      this.enableAppView();
      this.is_show_menu = !this.is_show_menu;
      if (data.channel_type_id === 1) {
        localStorage.setItem("email_account_id", data.email_account.id);
        localStorage.setItem("communication_channel_id", data.id);
     
        this.activeCommunicationChannel(data.email_account.id)
        this.emptyInbox();
        this.loadContact(+data.email_account.id)
        this.showChannel(data.id);
        this.loadTags(data.id);
        if (this.$route.path != '/emails') {
          this.$router.push('/emails').catch(() => { });
        }

      }

      if (data.channel_type_id === 2) {
        this.$router.push('/sms-channel').catch(() => { })
      }

      if (data.channel_type_id === 3) {
        this.$router.push('/chat-channel').catch(() => { })
      }
    },
    loadSingleChannel(id){
      this.showChannel(id)
    },

    logout() {
      localStorage.removeItem('connectxone_token');
      this.$router.push('/login').catch(() => { });

    },
    // loadContacts(){
    //   let contact_data = {
    //       search_term: "",
    //       last_contact_id: 0,
    //       // account_id:+account_id,
    //       limit: 24
    //     }
    // this.allEmailContact(contact_data)
    // },

    loadUser() {
      return this.getUserData();
    }
  },
  mounted() {
    this.loadUser();
    this.loadChannels();
    // this.loadContacts();
    // this.loadNotification();    
    window.onCxConnect = () => {
      this.is_connected = true
    }

    window.onCxDisconnect = ()=>{
      this.is_connected = false;
    }

    if(this.active_communication_channel !== null){
      if(this.active_communication_channel !== undefined){
        this.active_channel_id = this.active_communication_channel;
      }
    }else{
      const account_id = localStorage.getItem('email_account_id')
      if(account_id !== null){
        if(account_id !== undefined){
          this.active_channel_id  = account_id
          this.loadContact(+account_id)
        }
      }
    }
  },
}
</script>

<style>

</style>